import * as React from 'react';
import useCollapse from 'react-collapsed';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Image from 'next/image';

import Checkers from '../../../../helpers/class/Checkers';
import ViewPort from '../../../ViewPort';
import {useEffect} from "react";
import Link from "next/link";

export default function ListType ( props ) {

  const builder = props.builder;
  const content = props.content;
  const articles = props.articles;

  let container_class = 'container';
  let row_class = 'bg-white row align-items-stretch mb-5\ ';
  if (props.type == 'blog') {
    if (Checkers.isValidString(builder.builder_news_view_width)) {
      container_class = builder.builder_news_view_width;
    }
    row_class += " " + Checkers.isValidObjString(builder, 'builder_news_radius') ? ` border-radius-${builder.builder_news_radius}\  ` : '';
    row_class += " " + Checkers.isValidObjString(builder, 'builder_news_shadow') ? ` shadow-${builder.builder_news_shadow} ` : '';

  } else if (props.type == 'category') {
    if (Checkers.isValidString(content.category_view_width)) {
      container_class = content.category_view_width;
    }
    row_class += " " + Checkers.isValidObjString(content, 'tiles_radius') ? ` border-radius-${content.tiles_radius}` : '';
    row_class += " " + Checkers.isValidObjString(content, 'tiles_shadow') ? ` shadow-${content.tiles_shadow}` : '';

  }

  const [isExpanded, setExpanded] = React.useState(false);
  const {getCollapseProps} = useCollapse({
    isExpanded
  });

  let skeletonArticles = [];
  for (let i = 0; i < 6; i++) {
    skeletonArticles.push(
        <Skeleton key={i.toString()} height={432} className="col-12 b-rad-md mb-5"/>
    );
  }

  const subtitleHandler = (article, contentType) => {
    let subtitle = null;

    if (contentType == 'article') {
      subtitle = article['mainDescription']
    } else if (contentType == 'blog') {
      subtitle = article.title
    } else {
      subtitle = article.description
    }

    if (subtitle == null || subtitle.length == 0) {
      subtitle = article.mainDescription
    }

    return subtitle
  }

  const mainContentHandler = (article, isArticleCategory) => {
    let content = null;

    content = Checkers.booleanState(props.isArticleCategory) ? article.body : article.description

    // if(content===null || content.length==0){
    //   content = article.metaDesc
    // }

    if (content === null) {
      content = article.body
    }
    if (content != null && content.length == 0) {
      content = article.body
    }

    content = Checkers.isValidString(content) ? (content.slice(0, 200) + '...') : ""

    return content
  }

  const textColor = () => {
    if (props.textColor) {
      return "text-light-color"
    }

    return;
  }

  return (
      <div className={`${container_class} pb-5`}>
        <div className="row w-100 m-0">
          <div className="col-12 pb-5">
            {props.loadingArticles ?
                skeletonArticles
                : articles!=undefined && articles.length>0 && articles.map((article, key) => {

              const created =
                  moment(new Date((article.created) * 1000)).format('dddd Do MMMM')
              ;
              const title = props.articlesContentType == 'blog' ? created : article.title;
              const description = props.articlesContentType == 'article' || props.articlesContentType == 'blog' ? article.description : article.body;
              let subtitle = subtitleHandler(article, props.articlesContentType)
              if (subtitle === undefined || subtitle === null || subtitle.length === 0) {
                subtitle = props.articlesContentType == 'article' ? article.subtitle : props.articlesContentType == 'blog' ? article.title : article.description;
              }

              if ( (props.hasOwnProperty('articlesLimit') && (key<props.articlesLimit || props.articlesLimit===null)) || !props.hasOwnProperty('articlesLimit') ) {
                return (
                    <ViewPort
                        // parentClassName={col_class}
                        key={article.id.toString()}
                        skeletonItem={
                          <Skeleton height={432} className="col-12 b-rad-md mb-5"/>
                        }
                    >
                      <div key={key}
                           className={row_class + ' ' + (Checkers.isValidString(props.itemClassName) ? props.itemClassName : '')}
                           style={{
                             background: props.backgroundColor != null ? props.backgroundColor : ""
                             // borderTopLeftRadius: content.tiles_radius + "px",
                             // borderBottomLeftRadius: content.tiles_radius + "px", overflow: "hidden",
                             // boxShadow: '0px 0px '+content.tiles_shadow+'px -5px rgba(0, 0, 0, 0.25);'
                           }}
                      >
                        <div className={"col-12 col-sm-12 col-md-6 col-lg-4 col-xlg-4 col-photo-list p-0"}>
                          {
                              Checkers.isValidString(article.image) &&
                              <Image
                                  layout={'responsive'}
                                  width={300}
                                  height={300}
                                  sizes={'75vw'}
                                  quality={'75'}
                                  src={article.image}
                                  alt={'Post Img'}
                                  placeholder={'blur'}
                                  blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                              />
                          }
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-8 col-xlg-8 p-0"
                             style={{
                               background: props.backgroundColor != null ? props.backgroundColor : ""
                             }}>
                          <div className="d-flex flex-column h-100 px-5 py-5">
                            <div
                                className={"text-uppercase hint-text fw-bold small-text text-master text-design-color " + textColor()}>
                              {title}
                            </div>
                            <h2 className={" normal mb-0 " + textColor()}>{subtitle}</h2>
                            <div className={" content-wrapper line-clamp-5 m-t-20 " + textColor()}
                                 dangerouslySetInnerHTML={{__html: mainContentHandler(article, props.isArticleCategory)}}/>

                            {
                              description != null && description.length > 0 ?
                                  <>
                                    <div className={textColor()}
                                         dangerouslySetInnerHTML={{__html: description.slice(0, 250) + '...'}}/>
                                  </>
                                  :
                                  <></>
                            }
                            {
                                (article.has_description == '1' || Checkers.isValidString(article.description) || Checkers.isValidString(article.body) || article.show_when_empty == true) &&
                                <div className="row justify-content-end mt-auto">
                                  <div className="col-auto">
                                    <Link href={props.updateHref( article )}>
                                      <a className={"btn btn-link"}
                                         onClick={() => props.clickHandler( article )}
                                      >Read more...</a>
                                    </Link>
                                  </div>
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </ViewPort>
                )
              }
            })
            }
          </div>
        </div>
      </div>
  )
}
